import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"
import { makeStyles } from "@material-ui/core/styles"
import Link from "@material-ui/core/Link"
import uuid from "react-uuid"
import { Icon } from "@iconify/react"
import phoneFilled from "@iconify/icons-ant-design/phone-filled"
import homeFilled from "@iconify/icons-ant-design/home-filled"
import projectOutlined from "@iconify/icons-ant-design/project-outlined"
import informationIcon from "@iconify/icons-emojione-monotone/information"

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    background: "yellow",
  },
})

const navLinks = [
  {
    name: "Home",
    href: "#home",
    icon: homeFilled,
  },
  {
    name: "Projects",
    href: "#projects",
    icon: projectOutlined,
  },
  {
    name: "About",
    href: "#about",
    icon: informationIcon,
  },
  {
    name: "Contact Me",
    href: "#contact",
    icon: phoneFilled,
  },
]

const SideListComponent = ({ side, toggleDrawer }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {navLinks.map((item, index) => (
          <Link href={item.href} color="inherit" underline="none">
            <ListItem button key={uuid()}>
              <ListItemIcon>
                <Icon
                  icon={item.icon}
                  style={{
                    color: "#FFF",
                    fontSize: "1em",
                    margin: "0.25rem 0 0.25rem 0.5rem",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </Icon>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )
}

export default SideListComponent
