import React, { useEffect, useState } from "react"
// import firebaseConfig from "../components/firebase.config"
// import * as firebase from "firebase/app"
// import "firebase/analytics"
// import "firebase/firestore"

// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import "../components/index.css"
import Drawer from "@material-ui/core/Drawer"
import Tooltip from "@material-ui/core/Tooltip"
import SideListComponent from "../components/SideListComponent/SideListComponent"
import uuid from "react-uuid"
import { makeStyles } from "@material-ui/core/styles"
import { Icon } from "@iconify/react"
import phoneFilled from "@iconify/icons-ant-design/phone-filled"
import homeFilled from "@iconify/icons-ant-design/home-filled"
import projectOutlined from "@iconify/icons-ant-design/project-outlined"
import informationIcon from "@iconify/icons-emojione-monotone/information"
import baselinePhoneAndroid from "@iconify/icons-ic/baseline-phone-android"
import mailIcon from "@iconify/icons-bytesize/mail"

import axios from "axios"
import ContactFormComponent from "../components/ContactFormComponent/ContactFormComponent"

// const firebaseConfig = {
//   apiKey: "AIzaSyAPfshPe63TcUKnWZppRcLSRnTsfC9rONM",
//   authDomain: "myportfolio-2b9ba.firebaseapp.com",
//   databaseURL: "https://myportfolio-2b9ba.firebaseio.com",
//   projectId: "myportfolio-2b9ba",
//   storageBucket: "myportfolio-2b9ba.appspot.com",
//   messagingSenderId: "820596283572",
//   appId: "1:820596283572:web:62894be0d64ec9c063dfe1",
//   measurementId: "G-TL7EENZZ8V",
// }

// console.log("firebase config: ", firebaseConfig)

const useTooltipStyles = makeStyles({
  tooltip: {
    background: "rgb(237, 255, 157)",
    color: "black",
    fontSize: "1rem",
    borderRadius: "10px",
  },
})

const useDrawerStyles = makeStyles({
  paper: {
    background: "#9f2cb9",
    color: "white",
    fontFamily: "Oswald",
  },
})

const IndexPage = () => {
  const [navBarClass, setNavBarClass] = useState("nav-bar")
  const [db, setDB] = useState(null)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const classes = useTooltipStyles()
  const drawerClasses = useDrawerStyles()

  const socialMedia = [
    {
      title: "CodePen",
      className: "codepen",
      href: "https://codepen.io/geokdev",
    },
    {
      title: "LinkedIn",
      className: "linkedin",
      href: "http://www.linkedin.com/in/geokdev",
    },
    {
      title: "Instagram",
      className: "instagram",
      href: "https://www.instagram.com/geokdev/",
    },
    {
      title: "GitHub",
      className: "github",
      href: "https://github.com/geokdev",
    },
    {
      title: "Twitter",
      className: "twitter",
      href: "https://twitter.com/geokdev",
    },
  ]

  const projects = [
    {
      className: "calculator",
      title: "Calculator App",
      gitHubHref: "http://github.com/",
      demoHref:
        "https://5e64f032ab3c23000f75628e--gifted-golick-ffe39d.netlify.com/",
    },
    {
      className: "random-quotes",
      title: "Animated Draggable Random Quotes",
      gitHubHref: "http://github.com/",
      demoHref:
        "https://5e5ee2a3d3ed0000072b65e1--hungry-hoover-b8d9dc.netlify.com/",
    },
    {
      className: " ",
      title: "",
      gitHubHref: "http://github.com/",
      demoHref: "#",
    },
    {
      className: " ",
      title: "",
      gitHubHref: "http://github.com/",
      demoHref: "#",
    },
    {
      className: " ",
      title: "",
      gitHubHref: "http://github.com/",
      demoHref: "#",
    },
    {
      className: " ",
      title: "",
      gitHubHref: "http://github.com/",
      demoHref: "#",
    },
  ]

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setNavBarClass("nav-bar2")
    } else {
      setNavBarClass("nav-bar")
    }
  }

  const handleNameChange = input => {
    setName(input)
  }

  const handleEmailChange = input => {
    setEmail(input)
  }

  const handlePhoneChange = input => {
    setPhone(input)
  }

  // const handleSubmit = () => {
  //   if (db !== null) {
  //     db.collection("ContactFormMessages")
  //       .add({
  //         name: name,
  //         email: email,
  //         phone: phone,
  //         message: message,
  //       })
  //       .then(function(docRef) {
  //         console.log("Document written with ID: ", docRef.id)
  //       })
  //       .catch(function(error) {
  //         console.error("Error adding document: ", error)
  //       })
  //   }
  // }

  const handleSubmit = () => {
    // console.log("HANDLE CALLED")
    // let templateParams = {
    //   from_name: "George",
    //   from_email: "geocrunch@gmail.com",
    //   from_phone: "3339992222",
    //   message_html: "Custom Message",
    //   name: "James",
    //   notes: "Check this out!",
  }

  // window.emailjs
  // .send(
  //   "gmail",
  //   "template_wPYDs4NX",
  //   templateParams,
  //   "user_GcTnhiCGGcW8KV8ccH2o1"
  // )
  // .then(
  //   function(response) {
  //     console.log("SUCCESS!", response.status, response.text)
  //   },
  //   function(error) {
  //     console.log("FAILED...", error)
  //   }
  // )
  // }

  useEffect(() => {
    // console.log(firebaseConfig)
    window.addEventListener("scroll", scrollFunction)
    // const myProject = firebase.initializeApp(firebaseConfig)
    // console.log("Firebase Project Name: ", myProject.name)/
    // setDB(firebase.firestore())

    // const script = document.createElement("script")
    // script.src =
    //   "https://cdn.jsdelivr.net/npm/emailjs-com@2.3.2/dist/email.min.js"
    // script.async = true
    // document.body.appendChild(script)
    // script.onload = () => {
    //   console.log("Calling handleSubmit")
    //   handleSubmit()
    // }

    // console.log(script)

    return () => {
      //
    }
  }, [])

  return (
    <>
      <SEO title="Home" />
      <section id="home" className="home-page">
        <div className="top-logo">
          <div className="logo" />
          <div className="logo-text">GEORGE KURGUZOV</div>
        </div>
        <nav className={navBarClass} id="nav-bar">
          <a href="#home" className="nav-link home-link">
            HOME
            <Icon
              icon={homeFilled}
              style={{
                color: "#FFF",
                fontSize: "1em",
                margin: "0.25rem 0 0.25rem 0.5rem",
              }}
            />
          </a>
          <a href="#projects" className="nav-link projects-link">
            PROJECTS
            <Icon
              icon={projectOutlined}
              style={{
                color: "#FFF",
                fontSize: "1em",
                margin: "0.25rem 0 0.25rem 0.5rem",
              }}
            />
          </a>
          <a href="#about" className="nav-link about-link">
            ABOUT
            <Icon
              icon={informationIcon}
              style={{
                color: "#FFF",
                fontSize: "1em",
                margin: "0.25rem 0 0.25rem 0.5rem",
              }}
            />
          </a>
          <a href="#contact" className="nav-link contact-link">
            CONTACT ME
            <Icon
              icon={phoneFilled}
              style={{
                color: "#FFF",
                fontSize: "1em",
                margin: "0.25rem 0 0.25rem 0.5rem",
              }}
            />
          </a>
        </nav>
        <div
          className="hamburger-menu"
          onClick={toggleDrawer("right", true)}
        ></div>
        <Drawer
          classes={{ paper: drawerClasses.paper }}
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          <SideListComponent side="right" toggleDrawer={toggleDrawer} />
        </Drawer>
        <div className="social-media">
          {socialMedia.map(item => (
            <Tooltip key={uuid()} title={item.title} placement="right" arrow>
              <a
                href={item.href}
                target="_blank"
                className={`icon ${item.className}`}
              ></a>
            </Tooltip>
          ))}
        </div>
        <div className="name-portfolio">
          <div className="my-name">GEORGE KURGUZOV</div>
          <div className="portfolio">PORTFOLIO</div>
        </div>
        <div className="text-1">
          <div className="text-box-mobile">
            <p className="p-1">Hi, I'm George</p>
            <p className="p-2">I build professional web intelligence</p>
          </div>
          <a href="#projects" className="contact-button-mobile">
            Find Out More..
          </a>
          <div className="text-box-1">
            <p className="text-1-p">
              Hi, I'm George, <br />I build professional web intelligence. Feel
              free to browse around and{" "}
              <a href="#contact" style={{ color: "white" }}>
                reach out
              </a>{" "}
              if you have any questions.
            </p>
            <a href="#projects" className="contact-button">
              Find Out More..
            </a>
          </div>
        </div>
        <div className="balls" />
      </section>
      <div className="container">
        <a className="anchor" id="projects"></a>
        <section className="projects-page">
          <div className="tech-logos-group">
            <div className="tech-logo">
              <img src="./images/react-black.svg" alt="" /> React
            </div>
            <div className="tech-logo ">
              <img src="./images/Html5.svg" alt="" />
              HTML5
            </div>
            <div className="tech-logo ">
              <img src="./images/CSS.svg" alt="" />
              CSS3
            </div>
            <div className="tech-logo ">
              <img src="./images/Javascript.svg" alt="" className="src" />
              JavaScript
            </div>
          </div>
          <h2 className="projects-header">
            HERE ARE A FEW OF MY RECENT PROJECTS
          </h2>
          <div className="projects-container">
            {projects.map(item => (
              <div key={uuid()} className={`project-box ${item.className}`}>
                <div className="overlay">
                  <span className="text">{item.title}</span>
                  <Tooltip
                    enterDelay={300}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    title="Read more about the project and view the source code on GitHub"
                    placement="top"
                    arrow
                  >
                    <a
                      href={item.gitHubHref}
                      className="github"
                      target="_blank"
                    >
                      View GitHub
                    </a>
                  </Tooltip>
                  <a className="view" href={item.demoHref} target="_blank">
                    ViewDemo
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
        <a className="anchor" id="about"></a>
        <section className="about-page">
          <h1 className="about-header">About..</h1>
          <p className="about-p">
            I specialize in developing web applications and websites by
            utilizing modern web technologies such as ReactJS, JavaSricpt(ES6),
            GSAP animation and much more. Below is the list of some of my
            skills. Feel free to <a href="#contact">contact me</a> if you would
            like to find out more or if you have any questions.
          </p>
          <div className="skills-box">
            <h1 className="skills-header">MAIN SKILLS</h1>
            <div className="skill-section">
              <h3 className="skill-category">FRONT END</h3>
              <ul className="skill-list">
                <li className="skill-item">React / Redux</li>
                <li className="skill-item">JavaScript(ES6)</li>
                <li className="skill-item">HTML5/CSS3/SASS</li>
              </ul>
            </div>
            <div className="skill-section">
              <h3 className="skill-category">BACK END</h3>
              <ul className="skill-list">
                <li className="skill-item">Node/Next.JS/Gatsby</li>
                <li className="skill-item">PHP</li>
              </ul>
            </div>
            <div className="skill-section">
              <h3 className="skill-category">DATABASES</h3>
              <ul className="skill-list">
                <li className="skill-item">MongoDB</li>
                <li className="skill-item">PostgreSQL</li>
                <li className="skill-item">MySQL</li>
                <li className="skill-item">Firebase</li>
              </ul>
            </div>
          </div>
        </section>
        <a className="anchor" id="contact"></a>
        <section className="contact-page">
          <h1 className="contact-me">CONTACT INFO</h1>
          <address>
            <div className="phone-number">
              <Icon
                icon={baselinePhoneAndroid}
                style={{ color: "#000", fontSize: "2rem", marginRight: "2rem" }}
              />
              404-494-0006
            </div>
            <div className="email">
              <span
                className="iconify"
                data-inline="false"
                data-icon="bytesize:mail"
              />
              <Icon
                icon={mailIcon}
                style={{ color: "#000", fontSize: "2rem", marginRight: "2rem" }}
              />
              <a href="mailto: george.kurguzov@gmail.com" target="_top">
                GEORGE.KURGUZOV@GMAIL.COM
              </a>
            </div>
            <ContactFormComponent className="contact-form" />
          </address>
          <div className="thanks">THANKS FOR VISITING!</div>
          <div className="pics pic1" />
          <div className="pics pic2" />
        </section>
      </div>
      <footer>Designed and Built by George Kurguzov</footer>
    </>
  )
}

export default IndexPage
