import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"

import axios from "axios"

const formStyles = makeStyles(theme => ({
  root: {
    marginTop: "2rem",
    display: "grid",
    gap: "1rem",
  },
}))

const buttonStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#6e0080",
    margin: "auto",
    width: "10rem",
    "&:hover": {
      backgroundColor: "#c000df",
    },
  },
}))

export default function ContactFormComponent() {
  let initialFields = {
    name: "",
    email: "",
    phone: "",
    message: "",
  }
  const formClasses = formStyles()
  const buttonClasses = buttonStyles()
  const [sendStatus, setSendStatus] = useState(false)
  const [statusMessage, setStatusMessage] = useState("")
  const [field, setField] = useState(initialFields)

  const resetFormState = () => {
    setSendStatus(false)
    setStatusMessage("")
  }

  const handleNameChange = e => {
    setField({ ...field, name: e.target.value })
  }

  const handleNameKeyDown = e => {
    let regex = RegExp("^[a-z ]*$", "i")

    if (regex.test(e.key) || e.key === "Backspace") {
      //
    } else {
      e.preventDefault()
    }
  }

  const handlePhoneKeyDown = e => {
    let regex = RegExp("^[0-9]*$", "i")

    if (regex.test(e.key) || e.key === "Backspace" || e.key === "Tab") {
      //
    } else {
      e.preventDefault()
    }
  }

  const formatPhoneNumber = input => {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, "")

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10)

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length
    if (size == 0) {
      input = input
    } else if (size < 4) {
      input = "(" + input
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6)
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        " - " +
        input.substring(6, 10)
    }
    return input
  }

  const handlePhoneChange = e => {
    setField({ ...field, phone: formatPhoneNumber(e.target.value) })
  }
  const handleEmailChange = e => {
    setField({ ...field, email: e.target.value })
  }
  const handleMessageChange = e => {
    setField({ ...field, message: e.target.value })
  }

  const handleSubmit = e => {
    setSendStatus(true)
    setStatusMessage("Sending...")
    e.preventDefault()

    let params = new URLSearchParams()
    params.append("name", field.name)
    params.append("phone", field.phone)
    params.append("email", field.email)
    params.append("message", field.message)

    axios
      //   .post(`https://gkphpmailer.herokuapp.com/`, params)
      .post("/email/index.php", params)
      .then(res => {
        if (res.status === 200) {
          setStatusMessage(
            <>
              Thank you, {field.name},<br /> your message has been sent!
            </>
          )
          setField(initialFields)
        } else {
          setStatusMessage(
            <>
              Error - Server couldn't send the message,
              <br /> please try again later
            </>
          )
        }
      })
      .catch(error => {
        setStatusMessage(
          <>
            Error - Server couldn't send the message,
            <br /> please try again later
          </>
        )
      })
  }

  useEffect(() => {
    if (sendStatus === true) {
      setTimeout(() => resetFormState(), 5000)
    }
    return () => {
      //
    }
  }, [sendStatus])

  return (
    <>
      <div className="contact-form" id="contact-form">
        <span className="header">Send Me a Message</span>
        <ValidatorForm
          className={formClasses.root}
          style={{ textAlign: "center" }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextValidator
            id="filled-basic"
            label="Name"
            variant="filled"
            onChange={handleNameChange}
            onKeyDown={handleNameKeyDown}
            required
            value={field.name}
            validators={["required"]}
            errorMessages={["this field is required", "name is not valid"]}
          />
          <TextValidator
            id="filled-basic"
            label="Email"
            variant="filled"
            onChange={handleEmailChange}
            required
            value={field.email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
          />

          <TextValidator
            id="filled-basic"
            label="Phone (optional)"
            onKeyDown={handlePhoneKeyDown}
            variant="filled"
            onChange={handlePhoneChange}
            value={field.phone}
          />
          <TextValidator
            id="filled-basic"
            label="Message"
            multiline={true}
            variant="filled"
            rows="4"
            onChange={handleMessageChange}
            required
            validators={["required"]}
            errorMessages={["this field is required"]}
            value={field.message}
          />

          <Button
            className={buttonClasses.root}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </ValidatorForm>
      </div>

      {sendStatus && <div className="sent-message">{statusMessage}</div>}
    </>
  )
}
